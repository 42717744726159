import React, { useContext, useEffect, useRef, useState } from "react";
import { ApiService } from "../../Component/Service/apiservices";

const FilterSidebar = ({setParentSingleFilter, getProductListing, clearParentFilters, clearParentSingleFilter, setSelectedSort, setParentPriceRangeValue}) => {
    const didMountRef = useRef(true)
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [filters, setFilters] = useState([]);
    const [appliedfilters, setappliedfilters] = useState([]);
    const [appliedprice, setappliedprice] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);     
    const [priceRange, setPriceRange] = useState(null);

    useEffect(() => {
        if (didMountRef.current) {
            getCategories()
        }
        didMountRef.current = false;
    })

    useEffect(() => {
        
    }, [appliedfilters, selectedFilters])

    const getCategories = () => {
        ApiService.fetchData(`all-categories-with-products`).then((res) => {
            if (res.status == "success") {
                setCategories(res.resCategory)
                setFilters(res.attributesFilter)
                setSpinnerLoading(false)
            } else {
              setSpinnerLoading(false)
            }
        }).catch(() => { setSpinnerLoading(false) })
    }

    const setAttr = (id, text) => {
        if (!selectedFilters.includes(id)) {
            setSelectedFilters([...selectedFilters, id]);
        }else{
            setSelectedFilters(selectedFilters.filter(filterId => filterId !== id));
        }
        if (!appliedfilters.some(filter => filter.id === id)) {
            setappliedfilters([...appliedfilters, { 'id': id, 'text': text }]);
            setParentSingleFilter(id);
        }else{
            setappliedfilters(appliedfilters.filter(filter => filter.id !== id));
            clearParentSingleFilter(id);
        }
       
    }

    const clearFilters = () => {
        setappliedfilters([]);
        setSelectedFilters([]);        
        setappliedprice([]);
        setPriceRange(null);
        setParentPriceRangeValue(null)
        clearParentFilters();
    }
    const clearThisFilter = (filtervalue) => {
        clearParentSingleFilter(filtervalue)
        setSelectedFilters(selectedFilters.filter(filterId => filterId !== filtervalue));
        setappliedfilters(appliedfilters.filter(filter => filter.id !== filtervalue));
        if (appliedprice.some(filter => filter.id === filtervalue)) {
            setappliedprice([]);
            setPriceRange(null);
        }
    }
    const changeSort = (sort) => {
        setSelectedSort(sort)
    }

    const setPrice = (field) => {
        if(field.target.checked){
            setPriceRange(field.target.value);
            setParentPriceRangeValue(field.target.value)
            setappliedprice([{ 'id': field.target.value, 'text': field.target.value }]);
        }else{
            setPriceRange(null);
            setParentPriceRangeValue(null)
            setappliedprice([]);
        }
        
    }

    return <div className="sidebarInner">
    <div className="sidebarWidget">
        <div class="form-floating">
            <select class="form-select" id="SortBy" aria-label="Sort" onChange={(e) => {changeSort(e.target.value)}} >
                <option value={""} selected>Select</option>
                <option value={"lowtohigh"}>Price Low to High</option>
                <option value={"hightolow"}>Price high to Low</option>
                <option value={"newarrivals"}>New Arrivals</option>
            </select>
            <label for="SortBy">Sort</label>
        </div>
        </div>
        {appliedfilters.length > 0 || appliedprice.length > 0 ? <div className="sidebarWidget">
            <div className="sidebarWidgetHeader">
                <h5>Applied Filters</h5>
                <a href="javascript:void(0);" onClick={() => clearFilters()}>Clear all filters</a>
            </div>
            <div className="sidebarWidgetBody">
                <ul className="clearfilter">
                    {appliedfilters.map((filter,index) => {
                       return <li>
                            <div className="clearfilterbox">
                            <span>{filter.text}</span><i class="ri-close-line" onClick={() => clearThisFilter(filter.id)}></i>
                            </div>
                        </li>
                    })}
                    {appliedprice.length > 0 ? <>
                        {appliedprice.map((filter,index) => {
                        return <li>
                                <div className="clearfilterbox">
                                <span>{filter.text.replace('-', ' ')}</span><i class="ri-close-line" onClick={() => clearThisFilter(filter.id)}></i>
                                </div>
                            </li>
                    })}
                    </>:""}
                </ul>
            </div>
        </div> :""}

        <div className="sidebarWidget">
            <div className="sidebarWidgetHeader">
                <h5>Price</h5>
            </div>
            <div className="sidebarWidgetBody">
                <div class="el-checkbox">
                    <input type="checkbox" checked={priceRange === 'under-15000'} name="check" value={"under-15000"} onChange={(e) => setPrice(e)} id="1_1" />
                    <label class="el-checkbox-style" for="1_1"></label>
                    <span class="margin-r">Under 15000</span>
                </div>
                <div class="el-checkbox">

                    <input type="checkbox" checked={priceRange === 'under-40000'} name="check" value={"under-40000"} onChange={(e) => setPrice(e)} id="1_2" />
                    <label class="el-checkbox-style" for="1_2"></label>
                    <span class="margin-r">Under 40000</span>
                </div>
                <div class="el-checkbox">

                    <input type="checkbox" checked={priceRange === 'under-70000'} name="check" value={"under-70000"} onChange={(e) => setPrice(e)} id="1_3" />
                    <label class="el-checkbox-style" for="1_3"></label>
                    <span class="margin-r">Under 70000</span>
                </div>
                <div class="el-checkbox">

                    <input type="checkbox" checked={priceRange === 'above-70000'} name="check" value={"above-70000"} onChange={(e) => setPrice(e)} id="1_4" />
                    <label class="el-checkbox-style" for="1_4"></label>
                    <span class="margin-r">Above 70000</span>
                </div>
            </div>
        </div>
        {categories.length ? 
        <div className="sidebarWidget">
            <div className="sidebarWidgetHeader">
                <h5>Categories</h5>
            </div>
            <div className="sidebarWidgetBody">
                <ul className="slist">
                    {categories.map((category, index) => {
                        if(category.product_count > 0){
                            return <li key={index}><a href={"/list/category/" + category.cat_slug}>{category.cat_name} ({category.product_count})</a> </li>
                        }                        
                    })}
                </ul>
            </div>
        </div>
        :""
        }
        {filters.length ? <>
            {filters.map((filter,index) => {
                return <div className="sidebarWidget">
                    <div className="sidebarWidgetHeader">
                        <h5>{filter.attribute_name}</h5>
                    </div>
                    <div className="sidebarWidgetBody">
                    {filter.attribute_type === 1 ? 
                         <ul className="mslist">
                            {filter.attributeterms.map((terms,index) => {
                                return <li className={(selectedFilters.indexOf(terms.terms_id) > -1) ? "active":""} onClick={() => {setAttr(terms.terms_id, terms.terms_name)}}>
                                        <span className="colorb" style={{background: terms.terms_value}}></span>
                                        <span>{terms.terms_name}</span>
                                    </li>
                                })}
                        </ul>
                    : filter.attribute_type === 3 ? <ul className="sizelist">
                            {filter.attributeterms.map((terms,index) => {
                                return <li className={(selectedFilters.indexOf(terms.terms_id) > -1) ? "active":""} onClick={() => {setAttr(terms.terms_id, terms.terms_name)}}>{terms.terms_name}</li>
                                })}
                        </ul> : ""}
                    </div>   
                </div>
            
            })}
            
            </>: ""}
    </div>;    
};
export default FilterSidebar
  