import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import AccountSidebar from "./account_sidebar"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import React, { useContext, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../Component/Service/apiservices";
import moment from "moment";
import sessionCartData from "../../Component/Element/cart_session_data";
import AccountSubHeader from "../../Component/Header/account_subheader";






const MyOrder = () => {
  const navigate = useNavigate()
  const didMountRef = useRef(true);
  const [rowUserData, setRowUserData] = useState({});
  const [orderData, setOrderData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const dataArray = sessionCartData();
  const parsedCartSession = dataArray[1];
  useEffect(() => {
    if (didMountRef.current) {

      // getorderData();

    }
    didMountRef.current = false;
  }, []);


  const handleShopNow = () => {
    navigate("/");
  };


  const navigateback = (e) => {
    e.preventDefault()

    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };
  const getorderData = () => {
    ApiService.fetchData("get-order-data").then((res) => {
      if (res.status == "success") {
        setOrderData(res.orderList);
        setSpinnerLoading(false);
      } else {
        setSpinnerLoading(false);
      }
    });


  };

  const Step = ({ status, date, statustext }) => {
    const stepClasses = `col-3 bs-wizard-step ${status === 'active' ? 'active' : ''} ${status === 'complete' ? 'complete' : ''
      } ${status === 'disabled' ? 'disabled' : ''}`;

    return (
      <div className={stepClasses}>
        <div className="progress">
          <div className="progress-bar"></div>
        </div>
        <a href="#" className="bs-wizard-dot"></a>
        <div className="bs-wizard-info text-center">
          <p className="mb-0">{statustext}</p>
          {date ?
            <p className="mb-0 tx-12">{moment(date).format("DD MMM YYYY")}</p> : ''
          }

        </div>
      </div>
    );
  };

  return (<>


    <BrowserView>
      <Header innerHeader={'innerHeader'}></Header>
      <AccountSubHeader breadcrumbtitle={'Order History'}></AccountSubHeader>

      <section className="section-gap-medium">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <AccountSidebar></AccountSidebar>
            </div>
            <div className="col-lg-9">
              <div className="section-title mb-30">
                <h2>Order History</h2>
              </div>
              <div>
                {spinnerLoading === false ?
                  orderData.length > 0 ? (
                    orderData.map((value, index) => (
                      <div className="order-box" key={index}>
                        <div className="info">
                          <div className="info-delivery">
                            <h6 className="mb-1 tx-14">Delivery Address</h6>
                            <p className="mb-0 tx-13">
                              <strong>{value.trans_user_name}</strong>
                            </p>
                            <p className="mb-0 tx-13">
                              {value.trans_delivery_address}
                            </p>
                            <p className="mb-0 tx-13">
                              Email Id : {value.trans_user_email}
                            </p>
                            <p className="mb-0 tx-13">
                              Phone number : {value.trans_user_mobile}
                            </p>

                            {(() => {
                              if (value.trans_status == 1) {
                                return (
                                  <div className="row bs-wizard mt-5">
                                    <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                    <Step status="disabled" date="" statustext="Item Picked Up" />
                                    <Step status="disabled" date="" statustext="Shipped" />
                                    <Step status="disabled" date="" statustext="Delivered" />
                                  </div>
                                )
                              } else if (value.trans_status == 4) {
                                return (
                                  <div className="row bs-wizard mt-5">
                                    <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                    <Step status="complete" date={value.trans_pickedup_date} statustext="Item Picked Up" />
                                    <Step status="complete" date={value.trans_shiped_date} statustext="Shipped" />
                                    <Step status="complete" date={value.trans_delivered_date} statustext="Delivered" />
                                  </div>
                                )
                              } else if (value.trans_status == 5) {
                                return (
                                  <div className="row bs-wizard mt-5">
                                    <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                    <Step status="complete" date={value.trans_cancelled_date} statustext="Cancelled" />
                                  </div>
                                )
                              } else if (value.trans_status == 6) {
                                return (
                                  <div className="row bs-wizard mt-5">
                                    <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                    <Step status="complete" date={value.trans_pickedup_date} statustext="Item Picked Up" />
                                    <Step status="active" date={value.trans_shiped_date} statustext="Shipped" />
                                    <Step status="disabled" date={value.trans_delivered_date} statustext="Delivered" />
                                  </div>
                                )
                              } else if (value.trans_status == 7) {
                                return (
                                  <div className="row bs-wizard mt-5">
                                    <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                    <Step status="active" date={value.trans_pickedup_date} statustext="Item Picked Up" />
                                    <Step status="disabled" date={value.trans_shiped_date} statustext="Shipped" />
                                    <Step status="disabled" date={value.trans_delivered_date} statustext="Delivered" />
                                  </div>
                                )
                              } else if (value.trans_status == 2) {
                                return (
                                  <div className="row bs-wizard mt-5">
                                    <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                    <Step status="active" date={value.trans_pendingpayment_date} statustext="Payment Pending" />
                                    <Step status="disabled" date={value.trans_shiped_date} statustext="Shipped" />
                                    <Step status="disabled" date={value.trans_delivered_date} statustext="Delivered" />
                                  </div>
                                )
                              } else if (value.trans_status == 3) {
                                return (
                                  <div className="row bs-wizard mt-5">
                                    <Step status="complete" date={value.created_at} statustext="Order Placed" />
                                    <Step status="active" date={value.trans_onhold_date} statustext="On Hold" />
                                    <Step status="disabled" date={value.trans_shiped_date} statustext="Shipped" />
                                    <Step status="disabled" date={value.trans_delivered_date} statustext="Delivered" />
                                  </div>
                                )
                              }

                            })()}
                          </div>
                        </div>
                        <div className="bcode">
                          <div className="orderid-box mb-5">
                            <h6 className="mb-0">ORDER ID</h6>
                            <p className="mb-0 tx-13">{value.trans_order_number}</p>
                          </div>
                          <p className="tx-color-03 mb-0 tx-13">ORDER ON</p>
                          <p className="tx-12">
                            {moment(value.trans_datetime).format("ddd, DD MMM YYYY")}
                            <br />
                            {moment(value.trans_datetime, "HH:mm").format("hh:mm A")}

                          </p>
                          <a
                            href={`/account/order-details/${value.trans_order_number}`}
                            className="btn btn-primary-outline btn-medium btn-block"
                          >
                            View Details
                          </a>
                        </div>
                      </div>
                    ))
                  ) :
                    <div className="noimg" style={{ alignItems: 'start', justifyContent: "left" }}>
                      <h4>No orders found!</h4>
                      <p><a class="btn btn-underline-primary p-0" href="javascript:void(0)" onClick={handleShopNow}>Shop Now <i class="ri-add-circle-line"></i></a></p>
                    </div>

                  : null}
              </div>

            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </BrowserView>
    <MobileView>
      <header className="header mheader">
        <div className="header-left">
          <div className="header-title">
            <a href="javasccript:void(0)" onClick={(e) => { navigateback(e) }}><i className="ri-arrow-left-line ri-xl mr-10"></i></a>
            Order History
          </div>
        </div>
      </header>


      {orderData.length > 0 ? (
        orderData.map((value, index) => (
          <div className="morderbox mb-2" key={index}>
            <div className="morderbox-body">
              <div className="row">
                <div className="col-7">
                  <h6 className="mb-0 tx-14">Order Id: {value.trans_order_number}</h6>
                  <p className="mb-0 tx-13">Total Amount: ₹{value.trans_amt}</p>
                  <p className="mb-0 tx-13 tx-color-03">Items: {value.itemscount}</p>
                </div>
                <div className="col-5 tx-right">
                  <p className="mb-0 tx-13 tx-color-03">Placed On</p>
                  <p className="mb-0 tx-12 tx-color-03">{moment(value.trans_datetime).format("ddd, DD MMM YYYY")} @  {moment(value.trans_datetime, "HH:mm").format("hh:mm A")}</p>
                </div>
              </div>

            </div>
            <div className="morderbox-footer">
              <div className="morderbox-status bg-light-yellow">
                Processing
              </div>
              <div className="morderbox-link">
                <a href={`/account/order-details/${value.trans_order_number}`}>View Details<i className="d-icon-arrow-right"></i></a>
              </div>
            </div>
          </div>
        ))
      ) : <div className="noimg text-center">
        <img src="/img/no-orders.webp" style={{ width: '250px' }} className="mb-3" />
        <h6>No orders found!</h6>
        <p>Look like you haven't made your order yet</p>
        <button className="btn btn-primary-outline btn-medium btn01" onClick={handleShopNow}>Shop Now</button>
      </div>
      }

    </MobileView>

  </>)
}

export default MyOrder