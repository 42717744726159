import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import AccountSidebar from "./account_sidebar"
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { useContext } from "react";
import DataContext from "../../Component/Element/context";
import { useNavigate } from "react-router-dom";
import AccountSubHeader from "../../Component/Header/account_subheader";

const MyAccount = () => {
    const contextValues = useContext(DataContext)
    const navigate= useNavigate()
    
   
  const navigateback = (e) => {
    e.preventDefault()
   
    if (window.history.length > 2) { 
      navigate(-1);
    } else {
      navigate('/');
    }
  };
    return (<>
       
        <BrowserView>
        <Header innerHeader={'innerHeader'}></Header>
           
            <AccountSubHeader breadcrumbtitle={'overview'}></AccountSubHeader>
            <section className="section-gap-medium">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3">
                            <AccountSidebar ></AccountSidebar>
                        </div>
                        <div className="col-lg-6">
                          <div className="section-title mb-30">
                            <h2>Overview</h2>
                          </div>
                          <div className="recentbox">
                            <h5>Recent Order</h5>
                            <p>You haven't placed any orders yet.</p>
                          </div>
                          
                        </div>
                    </div>
                </div>
            </section>
           
            <Footer></Footer>
        </BrowserView>
        <MobileView>  


            <header className="header mheader">
                <div className="header-left">
                    <div className="header-title">
                        <a href="javasccript:void(0)" onClick={(e) => { navigateback(e) }}><i className="ri-arrow-left-line ri-xl mr-10"></i></a>
                    Overview
                    </div>
                </div>
            </header> 
            
        
        
        </MobileView>

     

    </>)
}

export default MyAccount