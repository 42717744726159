
import Modal from 'react-bootstrap/Modal';
import React, { useContext, useState, useEffect, useRef } from 'react';
import DataContext from '../Element/context';
import { ApiService } from '../Service/apiservices';
import Accordion from 'react-bootstrap/Accordion';
import constant from '../Service/constant';
import { addToCart, addToCartSession } from '../Element/add_to_cart';
import Loader from "react-js-loader";
import { toast } from "react-toastify";
import multiCurrency from '../Element/multiCurrency';
const VariationModal = () => {
    const contextValues = useContext(DataContext)
    const didMountRef = useRef(true);
    const [ProductsData, setProductsData] = useState({})
    const [adminData, setadminData] = useState({})
    const [galleryImage, setgalleryImage] = useState([])
    const [loading, setloading] = useState({})
    const [variationDataa, setVariationData] = useState([]);
    const [arySelectedData, setArySelectedData] = useState([]);
    const [selvararray, setSelvararray] = useState([]);
    const [termImageBaseUrl, setTermImageBaseUrl] = useState('');
    useEffect(() => {
        if (didMountRef.current) {
            if (contextValues.productData && Object.keys(contextValues.productData).length > 0) {
                getProductdetail()
            }
        }
        didMountRef.current = false;
    }, []);
    const variationModal = () => {
        contextValues.setToggleVariationModal(!contextValues.toggleVariationModal)
    }

    const getProductdetail = () => {
        const dataString = {
            slug: contextValues.productData.product_slug
        }
        ApiService.postData('product-details', dataString).then((res) => {
            if (res.status == 'success') {
                setTermImageBaseUrl(res.termImageBaseUrl)
                setProductsData(res.rowProductData)
                setgalleryImage(res?.arrayImages)
                setadminData(res.admin_data)
                setVariationData(res.variationData);
                setSelvararray(res.selvararray);
                res.variationData.map((parent) => {
                    if (parent.attributes && Number(parent.attributes.attribute_type) === 3) { //Text
                        parent.attr_terms.map((child) => {
                            if (res.selvararray.includes(child.terms_name)) {
                                arySelectedData.push({ attr: parent.attributes.attribute_name, terms: child.terms_name, terms_value: child.terms_name, terms_type: 3 });
                            }
                        });
                    } else if (parent.attributes && Number(parent.attributes.attribute_type) === 2) { //Image
                        parent.attr_terms.map((child) => {
                            if (res.selvararray.includes(child.terms_name)) {
                                arySelectedData.push({ attr: parent.attributes.attribute_name, terms: child.terms_name, terms_value: child.terms_image, terms_type: 2 });
                            }
                        });
                    } else if (parent.attributes && Number(parent.attributes.attribute_type) === 1) { //Color
                        parent.attr_terms.map((child) => {
                            if (res.selvararray.includes(child.terms_name)) {
                                arySelectedData.push({ attr: parent.attributes.attribute_name, terms: child.terms_name, terms_value: child.terms_value, terms_type: 1 });
                            }
                        });
                    }
                });
                setloading(false)
            } else {
                setloading(false)
            }
        }).catch((error) => {
            setloading(false)
        })
    }

    const variationSelect = (attrTerm, index, valueVariation) => {
        const updatedData = arySelectedData.map(item => {
            if (item.attr === valueVariation.attributes.attribute_name) {
                let terms_value;
                switch (Number(item.terms_type)) {
                    case 1: //Color
                        terms_value = attrTerm.terms_value;
                        break;
                    case 2: //Image
                        terms_value = attrTerm.terms_image;
                        break;
                    case 3: //Text
                        terms_value = attrTerm.terms_name;
                        break;
                    default:
                        terms_value = item.terms_value;
                }
                return { ...item, terms: attrTerm.terms_name, terms_value, terms_type: item.terms_type };
            }
            return item;
        });
        const dataString = {
            variation: updatedData,
            product_id: ProductsData.product_id,
        };
        ApiService.postData("variation-wise-price", dataString).then((res) => {
            setArySelectedData(updatedData);
            setgalleryImage(res.arrayImages);
            ProductsData.product_sku = res.data.pv_sku;
            ProductsData.product_selling_price = res.data.pv_sellingprice;
            ProductsData.product_price = res.data.pv_price;
            ProductsData.product_stock = res.data.pv_quantity;
            ProductsData.product_moq = res.data.pv_moq;
            ProductsData.product_discount = res.data.pv_discount;
            setProductsData(ProductsData);
            
        });
    };
    function triggerEvent( elem, event ) {
    var clickEvent = new Event( event ); // Create the event.
    elem.dispatchEvent( clickEvent );    // Dispatch the event.
    }
    const addToCartProcess = async (addproduct) => {
        if (parseFloat(addproduct.product_selling_price) > 0) {
            const productData = {
                product_id: Number(addproduct.product_id),
                product_name: addproduct.product_name,
                product_slug: addproduct.product_slug,
                product_image: galleryImage ? galleryImage[0] : constant.DEFAULT_IMAGE,
                product_type: Number(addproduct.product_type),
                product_price: parseFloat(addproduct.product_price).toFixed(2),
                product_selling_price: parseFloat(addproduct.product_selling_price).toFixed(2),
                product_discount: addproduct.product_discount,
                product_variation: selvararray,
                product_category_id: addproduct.product_category_id,
                selected_variation: arySelectedData,
                quantity: Number(1),
            };
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (contextValues.userToken) {
                const updateStatus = await addToCartSession(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    variationModal()
                    cartModal()
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    variationModal()
                    cartModal()
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const cartModal = () => {
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }
    return (<>
        <Modal show={contextValues.toggleVariationModal} onHide={(e) => variationModal()} className='right cartmodal'>
            <div className='cartmodalBody'>
                <div className='cartmodalHeader'>
                    <h5 className='mb-0 tx-uppercase'></h5>
                    <button type="button" className="cart-close" onClick={() => { variationModal() }}><i className="ri-close-line ri-xl"></i></button>
                </div>
                <div className='cartmodal-scroll'>
                    <div className='cartProduct d-flex'>
                        <figure className='cartProductMedia mr-15'>
                            <img src={ProductsData.product_image}></img>
                        </figure>
                        <div className='cartProductDetails'>
                            <h2 className='title'><a href={'/product/' + ProductsData.product_slug}>{ProductsData.product_name}</a></h2>
                            <div class="product-price">
                                {ProductsData?.product_selling_price && (<ins className="new-price">{multiCurrency(ProductsData?.product_selling_price)}</ins>)}
                                {ProductsData?.product_price && Number(ProductsData?.product_price) > Number(ProductsData?.product_selling_price) && (<del className="old-price">{multiCurrency(ProductsData?.product_price)}</del>)}
                            </div>
                        </div>
                    </div>
                    {variationDataa && variationDataa.length > 0 && (
                        <>
                            <div className="variationAccord">
                                <Accordion eventKey="0">
                                    {variationDataa.map((valueVariation, indexVariation) => {
                                        if (valueVariation.attributes && valueVariation.attributes.attribute_type === 1) { // Color
                                            const matchedRow = arySelectedData.find(
                                                item => item.attr === valueVariation.attributes.attribute_name
                                            );
                                            return (
                                                <>
                                                    <Accordion.Item eventKey={indexVariation}>
                                                        <h6 className="mb-0 tx-14">{valueVariation.attributes.attribute_name}</h6>
                                                        <Accordion.Header>
                                                            <div className="productVarationMeta">
                                                                <span className="color active" data-src="/img/default_image.png" href="javascript:void(0)" style={{ background: matchedRow.terms_value }}></span>
                                                                {matchedRow ? matchedRow.terms : ""}
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            {valueVariation.attr_terms.map((valueVariationAttr, indexvalueVariationAttr) => {
                                                                const stringIncluded = matchedRow && matchedRow.terms === valueVariationAttr.terms_name ? true : false;
                                                                const className = stringIncluded ? "color active" : "color";
                                                                return (
                                                                    <div onClick={() => variationSelect(valueVariationAttr, indexVariation, valueVariation)}
                                                                        className={'productVarationMeta'}
                                                                        key={indexvalueVariationAttr}
                                                                    >
                                                                        <span class={className} data-src="/img/default_image.png" href="javascript:void(0)" style={{ background: valueVariationAttr.terms_value }}></span>
                                                                        {valueVariationAttr.terms_name}</div>
                                                                );
                                                            }
                                                            )}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </>
                                            );
                                        } else if (valueVariation.attributes && valueVariation.attributes.attribute_type === 2) { //Image
                                            const matchedRow = arySelectedData.find(
                                                item => item.attr === valueVariation.attributes.attribute_name
                                            );
                                            return (
                                                <>
                                                    <Accordion.Item eventKey={indexVariation}>
                                                        <h6 className="mb-0 tx-14">{valueVariation.attributes.attribute_name}</h6>
                                                        <Accordion.Header>
                                                            <div className="productVarationMeta">
                                                                <span className="swatch active" data-src={matchedRow.terms_value != null ? termImageBaseUrl + matchedRow.terms_value : constant.DEFAULT_IMAGE} href="javascript:void(0)" style={{ background: '#e6ca83' }}>
                                                                    <img src={matchedRow.terms_value != null ? termImageBaseUrl + matchedRow.terms_value : constant.DEFAULT_IMAGE}></img>
                                                                </span>
                                                                {matchedRow ? matchedRow.terms : ""}
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            {valueVariation.attr_terms.map((valueVariationAttr, indexvalueVariationAttr) => {
                                                                const stringIncluded = matchedRow && matchedRow.terms === valueVariationAttr.terms_name ? true : false;
                                                                const className = stringIncluded ? "swatch active" : "swatch";
                                                                return (
                                                                    <div onClick={() => variationSelect(valueVariationAttr, indexVariation, valueVariation)}
                                                                        className="productVarationMeta"
                                                                        key={indexvalueVariationAttr}
                                                                    >
                                                                        <span className={className} data-src={valueVariationAttr.terms_image != null ? termImageBaseUrl + valueVariationAttr.terms_image : constant.DEFAULT_IMAGE} href="javascript:void(0)" style={{ background: '#e6ca83' }}>
                                                                            <img src={matchedRow.terms_value != null ? termImageBaseUrl + matchedRow.terms_value : constant.DEFAULT_IMAGE}></img>
                                                                        </span>
                                                                        {valueVariationAttr.terms_name}
                                                                    </div>
                                                                );
                                                            }
                                                            )}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </>
                                            );
                                        } else if (valueVariation.attributes && valueVariation.attributes.attribute_type === 3) { // Text
                                            const matchedRow = arySelectedData.find(
                                                item => item.attr === valueVariation.attributes.attribute_name
                                            );
                                            return (
                                                <>
                                                    <Accordion.Item eventKey={indexVariation}>
                                                        <h6 className="mb-0 tx-14">{valueVariation.attributes.attribute_name}</h6>
                                                        <Accordion.Header>{matchedRow ? matchedRow.terms : ""}</Accordion.Header>
                                                        <Accordion.Body>
                                                            {valueVariation.attr_terms.map((valueVariationAttr, indexvalueVariationAttr) => {
                                                                const stringIncluded = matchedRow && matchedRow.terms === valueVariationAttr.terms_name ? true : false;
                                                                const className = stringIncluded ? "text active" : "text";
                                                                return (
                                                                    <div onClick={() => variationSelect(valueVariationAttr, indexVariation, valueVariation)}
                                                                    className="productVarationMeta"
                                                                    key={indexvalueVariationAttr}>
                                                                    {valueVariationAttr.terms_name}
                                                                    </div>
                                                                );
                                                            }
                                                            )}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </>
                                            );
                                        }
                                        return null;
                                    })}
                                </Accordion>
                            </div>
                        </>
                    )}
                    <button type="button" className="btn btn-primary btn-full mt-15 mb-15" onClick={(e) => addToCartProcess(ProductsData)}>{contextValues.spinnerCubLoader == ProductsData.product_id ? <Loader type="spinner-default" bgColor={'#fff'} color={'#fff'} size={30} /> : 'Add to Cart'}</button>
                </div>
            </div>
        </Modal>
    </>)
}
export default VariationModal