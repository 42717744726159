import { useNavigate, useParams } from "react-router-dom"
import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import { ApiService } from "../../Component/Service/apiservices"
import { useState, useRef, useEffect } from "react"
import sessionCartData from "../../Component/Element/cart_session_data"

const VerifyEmail = () => {
    const dataArray = sessionCartData();
    const navigate= useNavigate()
    const {token}= useParams()
    const [successmessage, setsuccessmessage] = useState('')
    const [errormessage, seterrormessage] = useState('')
    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {
            emailVerify()
        }
        didMountRef.current = false
    },[])

    const emailVerify = () => {
        const dataString = {
            token: token,
            session_data: dataArray[1]
        }
        ApiService.loginProccessPostData('emailverify', dataString).then((res) => {
            if (res.status == 'success') {
                setsuccessmessage(res.message)
            } else {
                seterrormessage(res.message)
            }
        })
    }
    return (<>
    <Header innerHeader={'innerHeader'}></Header>
        <section>
            <div className="container-fluid px-0">
                <div className="row g-0">
                    <div className="col-lg-6">
                        <img src="/img/loginimg.jpg" className="img-fluid" />
                    </div>
                    <div className="col-lg-6">
                        <div className="loginrigisterbox">
                            <div className="tx-center mb-20">
                                <h4>Verify Email</h4>
                            </div>
                            <div className="text-center align-item-center">
                                <img src='/img/verified.gif'></img>
                                <h2>Account Verified</h2>
                                <p>Thank you for confirming your email. Your account is verified now . Start purhasing your shopping from <a href='/'>here</a></p>
                            </div>
                            <button className="btn btn-lg btn-primary btn-full mb-20 mt-20" onClick={() => { navigate('/') }}> Shop Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </>)
}

export default VerifyEmail