import { useLocation, useNavigate } from "react-router-dom";
import React , {useRef, useEffect, useState, useContext} from 'react'
import { toast } from "react-toastify";
import { ApiService } from "../../Component/Service/apiservices";
import DataContext from "../../Component/Element/context";
const Accountsidebar=()=>{
const contextValues= useContext(DataContext)
const location = useLocation()
const didMountRef = useRef(true);
const navigate = useNavigate()
const [userData , setUserData] = useState({})
useEffect(() => {
    if (didMountRef.current) {
        getUserData()
    }
    didMountRef.current = false;
    }, []);
const logoutProcess = () => {
const confirmed = window.confirm("Are you sure ? Want to log out");
if (confirmed) {
ApiService.fetchData("logout").then((res) => {
if (res.status === "success") {
localStorage.removeItem("USER_TOKEN")
window.location.href='/'
}
})
}
}

const getUserData=()=>{
    ApiService.fetchData('get-user-data').then((res)=>{
        if(res.status=='success'){
            setUserData(res.rowUserData)
            contextValues.setRowUserData(res.rowUserData)
        }
        else if(res.status=='error' && res.message=='Session expired'){
            localStorage.removeItem('USER_TOKEN')
            window.location.href='/'
        }
    }).catch((error)=>{

    })
}


console.log(contextValues)
return(<>
<div className="account-sidebar">
<div className="aclist mb-3">
<ul>
<li className={location.pathname=='/my-account' ? 'active':''}>
<a href="/my-account">{location.pathname=='/my-account' ? <i class="ri-arrow-right-line"></i>:''}Overview</a>
</li>
<li className={location.pathname=='/account/profile' ? 'active':''}>
<a href="/account/profile">{location.pathname=='/account/profile' ? <i class="ri-arrow-right-line"></i>:''}Account Detail</a>
</li>
<li className={location.pathname=='/account/my-address' ? 'active':''}>
<a href="/account/my-address">{location.pathname=='/account/my-address' ? <i class="ri-arrow-right-line"></i>:''}Addresses</a>
</li>
<li className={location.pathname=='/account/my-order' ? 'active':''}>
<a href="/account/my-order">{location.pathname=='/account/my-order' ? <i class="ri-arrow-right-line"></i>:''}Order History</a>
</li>
<li className={location.pathname=='/account/wishlist' ? 'active':''}>
<a href="/account/wishlist">{location.pathname=='/account/wishlist' ? <i class="ri-arrow-right-line"></i>:''}Wishlist</a>
</li>
{/* <li className={location.pathname=='/help-support' ? 'active':''}>
<a href="/help-support">Help &amp; Support</a>
</li> */}
</ul>
</div>
<div className="aclist">
<ul>
<li>
<a href="javascript:void(0)" className="pb-0 pt-0" onClick={()=>{logoutProcess()}}>Logout</a>
</li>
</ul>
</div>
</div>

</>)
}
export default Accountsidebar