import Footer from "../../Component/Footer";
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import Header from "../../Component/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar } from 'swiper/modules';
import ProductBox from "../../Component/Element/product_box";
import { useNavigate } from 'react-router-dom';
import sessionCartData from "../../Component/Element/cart_session_data";
import DataContext from "../../Component/Element/context";
import { ApiService } from "../../Component/Service/apiservices";
import { addToCart, addToCartSession, minusToCart, minusToCartSession, removeToCart, removeToCartSession } from "../../Component/Element/add_to_cart";
import { toast } from "react-toastify";
import Loader from "react-js-loader";
import multiCurrency from "../../Component/Element/multiCurrency";
const Cart = () => {
    const dataArray = sessionCartData();
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [recomendedProductList, setrecomendedProductList] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const navigate = useNavigate()
    const didMountRef = useRef(true)

    const contextValues = useContext(DataContext)
    const sliderRef = useRef(null);
    useEffect(() => {
        if (didMountRef.current) {
            if (localStorage.getItem("USER_TOKEN")) {
                getCartSessionData();
            } else {
                contextValues.setCartSessionData(dataArray[1])
                contextValues.setCartCount(dataArray[1].length)
                contextValues.setCartSummary(dataArray[3])
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
            getrecommendedProduct()
        }
        didMountRef.current = false;
    }, []);

    const getCartSessionData = () => {
        const dataString = {
            coupon_session: localStorage.getItem("COUPON_SESSION"),
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {
                contextValues.setCartSessionData(res.data.resCartData)
                contextValues.setCartCount(res.data.resCartData.length)
                contextValues.setCartSummary(res.data.cartSummary)
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            } else {
                contextValues.setCartSessionData([])
                contextValues.setCartCount(0)
                contextValues.setCartSummary({})
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
        });
    }

    const getrecommendedProduct = () => {
        ApiService.fetchData('/recommendedproductslist').then((res) => {
            if (res.status == 'success') {
                setrecomendedProductList(res.recommendedproducts)
            } else {
            }
        }).catch(() => {
        })
    }

    const addToCartProcess = async (addproduct) => {
        if (parseFloat(addproduct.product_selling_price) > 0) {
            const productData = {
                product_id: Number(addproduct.product_id),
                product_name: addproduct.product_name,
                product_slug: addproduct.product_slug,
                product_image: addproduct.product_image,
                product_type: Number(addproduct.product_type),
                product_price: parseFloat(addproduct.product_price).toFixed(2),
                product_selling_price: parseFloat(addproduct.product_selling_price).toFixed(2),
                product_discount: addproduct.product_discount,
                product_variation: addproduct.product_variation,
                product_category_id: addproduct.product_category_id,
                selected_variation: addproduct.selected_variation,
                quantity: addproduct.quantity,
            };
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (localStorage.getItem("USER_TOKEN")) {
                const updateStatus = await addToCartSession(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const minusToCartProcess = async (addproduct) => {
        if (parseFloat(addproduct.product_selling_price) > 0) {
            contextValues.setSpinnerCubLoader(addproduct.product_id)
            if (localStorage.getItem("USER_TOKEN")) {
                const updateStatus = await minusToCartSession(addproduct, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await minusToCart(addproduct, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const removeToCartProcess = async (productData) => {
        setSpinnerLoading(false);
        if (localStorage.getItem("USER_TOKEN")) {
            const updateStatus = await removeToCartSession(productData, contextValues);
            if (updateStatus) {
                setSpinnerLoading(false);
            } else {
                setSpinnerLoading(false);
            }
        } else {
            const updateStatus = await removeToCart(productData, contextValues);
            if (updateStatus) {
                setSpinnerLoading(false);
            } else {
                setSpinnerLoading(false);
            }
        }
    };

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);
    return (<>
        <Header innerHeader={'innerHeader'}></Header>
        <section className="section-gap-md">
            {contextValues.cartSessionData.length > 0 ?
                <>
                    <div className="container-fluid">
                        <div className="section-title mb-40">
                            <h4>Shopping Bag {contextValues.cartSessionData && contextValues.cartSessionData.length > 0 && (contextValues.cartSessionData.length)}</h4>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-lg-8">
                                <table className="shop-table cart-table">
                                    <thead>
                                        <tr>
                                            <th><span>Product</span></th>
                                            <th></th>
                                            <th><span>Price</span></th>
                                            <th><span>quantity</span></th>
                                            <th className="tx-right">total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contextValues.cartSessionData.map((value, index) => {
                                            return (
                                                <tr>
                                                    <td className="product-thumbnail">
                                                        <figure>
                                                            <a href={'/product/' + value.product_slug}>
                                                                <img src={value.product_image}></img>
                                                            </a>
                                                        </figure>
                                                    </td>
                                                    <td className="product-name">
                                                        <h2 className="product-name">
                                                            <a href={'/product/' + value.product_slug}>{value.product_name}</a>
                                                        </h2>
                                                        {value.product_type == 1 && value.selected_variation && value.selected_variation.length > 0 ?
                                                            <>
                                                                {value.selected_variation.map((value, index) => {
                                                                    return (<p className="tx-14" key={index}>{value.attr}: {value.terms}</p>)
                                                                })}
                                                            </>
                                                            : null}
                                                    </td>
                                                    <td className="product-price">
                                                        <span className="amount">{multiCurrency(value.product_selling_price)}</span>
                                                    </td>
                                                    <td className="product-quantity">
                                                        <div className="cartProductqty">
                                                            <span onClick={(e) => minusToCartProcess(value)}><i className="ri-subtract-line"></i></span>
                                                            <span>{contextValues.spinnerCubLoader == value.product_id ? <div className='buttonLoader'><Loader type="spinner-default" bgColor={'#121212'} color={'#121212'} size={30} /> </div> : value.quantity}</span>
                                                            <span onClick={(e) => addToCartProcess(value)}><i className="ri-add-line"></i></span>
                                                        </div>
                                                    </td>
                                                    <td className="product-price tx-right">
                                                        <div className="priceTotalTable">
                                                            <span className="amount">{multiCurrency(parseFloat(value.product_selling_price) * Number(value.quantity))}</span>
                                                            <a href="javascript:void(0)" onClick={(e) => removeToCartProcess(value)} className="product-remove" title="Remove this product">
                                                                <i className="ri-delete-bin-6-line"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-3">
                                <div className="cartSummaryBox">
                                    <ul>
                                        <li>
                                            <span className="tx-18 fw600">SUBTOTAL</span>
                                            <span className="tx-18 fw600">{multiCurrency(contextValues.cartSummary.itemTotal)}</span>
                                        </li>
                                        {/* <li>
                                            <span>Sales tax</span>
                                            <span>$0.00</span>
                                        </li> */}
                                    </ul>
                                    <button className="btn btn-primary btn-full btn-lg" type="button" onClick={(e) => navigate("/address")}>Proceed to Checkout</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <div className='cartmodal-scroll'>
                    <div className='noimg'>
                        <img src='/img/empty-cart.webp' className='wd-250' alt='cart'></img>
                        <h5>Your Cart is Empty</h5>
                        <p>It looks like you haven't added any items to your cart yet.</p>
                        <a href='/' className='btn btn-primary-outline'>Continue Shopping</a>
                    </div>
                </div>
            }
        </section>
        {recomendedProductList && recomendedProductList.length > 0 &&
            <section className="section-gap-md">
                <div className="section-title tx-center mb-20">
                    <h4>You May Also Like</h4>
                </div>
                <div className="productSlider">
                <Swiper
                    ref={sliderRef}
                  
                    modules={[Navigation, Scrollbar,]}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                    }}
                >
                    {recomendedProductList.map((value, index) => {
                        return (
                            <SwiperSlide >
                                <ProductBox productValue={value} classType="product" key={index}></ProductBox> 
                            </SwiperSlide>
                        )
                    })} 
                </Swiper>
                <div className='swiper-arrow'>
                    <div className="prev-arrow" onClick={handlePrev}><img src='/img/leftarrow.svg' alt="Previous" /></div>
                    <div className="next-arrow" onClick={handleNext}><img src='/img/rightarrow.svg' alt="Next" /></div>
                </div>
                </div>
               
            </section>
        }
        <Footer></Footer>
    </>)
}

export default Cart